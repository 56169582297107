<template>
  <div>
    <b-container>
      <h1 class="mt-3">Stage 1</h1>
      <h3>Iso-Seq Subreads Pre-Processing</h3>
      <p class="text-left">Step 1: Circular Consensus Sequence Calling (CCS)<br/>
      Step 2: Primer removal and demultiplexing (lima)<br/>
      Step 3: Reads refine: 1) Trimming of poly(A) tails; 2) Rapid concatmer identification and removal (isoseq3)</p>
      
      <p class="m-0 font-weight-bold">Select the folder which contains the relevant data for pre-processing:</p>
      <b-row><b-col>
        <b-alert show variant="danger" v-if=errorMsg>{{ errorMsg }}
          <div v-if=(errorShowRefresh)>Please refresh this page and try again, or contact an administrator if you continue to see this error.</div>
        </b-alert>
      </b-col></b-row>
    
      <b-form @submit.prevent="submitJob">
        <folder-browser :folders="folders" :selectedFolder="selectedFolder"
          @select-folder="folderSelected">
        </folder-browser>

        <b-form-checkbox
          id="ShortReadCheckbox"
          v-model="SRNeeded"
          name="ShortReadCheckbox"
        >
        Tick if you need to include results from a completed Shortread Pipeline run.
      </b-form-checkbox>

      <folder-browser v-if="SRNeeded" :folders="SRFolders" :selectedFolder="selectedSRFolder"
        @select-folder="SRFolderSelected">
      </folder-browser>

      <b-form-checkbox
          id="NameCheck"
          v-model="nameCheck"
          name="NameCheck"
        >
          Tick if you want to use a custom name for the results folder.
        </b-form-checkbox>

        <b-row v-if="nameCheck" class="input-row">
          <b-input-group>
            <b-input-group-prepend class="input-label">
              <b-input-group-text class="bg-white w-100">Custom folder name:</b-input-group-text>
            </b-input-group-prepend>
            <div class="input-box">
              <b-form-input id="nameInput" v-model="name" type="text" class="bg-secondary"></b-form-input>
            </div>
          </b-input-group>
        </b-row>

        <b-button type="submit" variant="primary" :disabled="selectedFolder.files.length === 0 || errorMsg != null">Submit</b-button>
      </b-form>

    </b-container>
  </div>
</template>

<script>
import FolderBrowser from '../components/FolderBrowser.vue'
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: "preProcessing",
  components: {
    "folder-browser": FolderBrowser
  },
  data() {
    return {
      // Error message that we want to display (if any)
      errorMsg: null,
      // Should the UI show a 'please refresh' msg when showing the error
      errorShowRefresh: true,
      data: null,
      folders: null,
      selectedFolder: {
        name: null,
        files: []
      },
      SRFolders: null,
      selectedSRFolder: {
        name: null,
        files: []
      },
      SRNeeded: false,
      nameCheck: false,
      name: ""
    }
  },

  computed: {
    ...mapGetters({token: "token"})
  },

  mounted() {
    axios.get("/folders?stage=lr1",
      {
        params: { token: this.token },
        headers: {"Content-Type": "application/json"}
      }).then(
        function(response) {
          this.folders = response.data;
          this.onLoad();
        }.bind(this)
      )
      .catch(error => {
        console.log(error)
        this.errorMsg = "Unable to retrieve a list of folders.";
      });

      axios.get("/folders?stage=sr5",
      {
        params: { token: this.token },
        headers: {"Content-Type": "application/json"}
      }).then(
        function(response) {
          this.SRFolders = response.data;
          this.onLoad();
        }.bind(this)
      )
      .catch(error => {
        console.log(error)
        this.errorMsg = "Unable to retrieve a list of folders.";
      });
  },

  methods: {
    onLoad: function() {
      if(this.$route.params.resubmitData != null && this.$route.params.resubmitData != undefined)
      {
        this.folderID = this.$route.params.resubmitData.dataFolder;
        if(this.$route.params.resubmitData != null && this.$route.params.resubmitData != undefined)
        {
          if(this.folderID !== undefined && this.folderID !== null)
          {
            this.folders.forEach(folder => {
              if((folder.id.localeCompare(this.folderID) == 0))
              {
                this.folderSelected(folder);
              }
            });
          }
        }
      }
    },

    folderSelected: function(folder) {
      this.selectedFolder = folder;
    },

    SRFolderSelected: function(folder) {
      this.selectedSRFolder = folder;
    },


    submitJob: function() {
      var custName = null;
      if(this.nameCheck) {
        custName = this.name;
      }

      var srDataID = "";
      if(this.SRNeeded) {
        srDataID = this.selectedSRFolder.id;
      }

      axios.get("/longread/preprocessing", {
        params: {
          token: this.token,
          dataFolderID: this.selectedFolder.id,
          srDataFolderID: srDataID,
          name: custName
        },
        headers: {"Content-Type": "application/json"}
        }).then(
          function(response) {
            this.data = response.data;

            this.$router.push({ name: 'tracking', params: { folderID: response.data.folderID }})

          }.bind(this)
        )
        .catch(error => {
          console.log(error)
          this.errorMsg = "ERROR";
        });
    }
  }
}
</script>

<style scoped>
.error {
  color: #ff0000;
}
</style>